import React, { useState, useEffect } from "react";
import { db, cf, auth, analytics } from "../firebase";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Paper,
	CssBaseline,
	Tab,
	Tabs,
	Typography,
	TextField,
	Button
} from "@material-ui/core";
import Error from "@material-ui/icons/Error";
import QueryString from "query-string";
import jwt from "jsonwebtoken";
import withSizes from "react-sizes";
import _ from "lodash";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import * as errorAnimation from "../assets/errorAnimation.json";
import * as offerSubmitted from "../assets/offerSubmitted.json";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import SwipeableViews from "react-swipeable-views";
import validator from "validator";

import Fade from "react-reveal/Fade";

import {
	Products,
	Header,
	ErrorSnackbar,
	NonOfferableError,
	FacebookPixel,
	OfferSelected
} from "../components";
import { Submit } from "./index";

if (
	window.location.hostname !== "localhost" &&
	window.location.hostname !== "127.0.0.1"
) {
	FacebookPixel.pageView();
}

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: 500,
		margin: "0em auto",
		marginTop: "4.5em"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
		flexBasis: "40%",
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	},
	textField: {
		width: "100%"
	},
	expansionPanel: {
		margin: "0px !important"
	},
	paper: {
		margin: "auto",
		border: "1px solid",
		borderColor: theme.palette.secondary.light,
		borderRadius: "0px"
	},
	indicator: {
		backgroundColor: theme.palette.primary.main
	},
	button: {
		color:
			theme.palette.primary.main === "#35B2FF" ||
			theme.palette.primary.main === "#35b2ff"
				? "#FFF"
				: theme.palette.primary.contrastText,
		background: theme.palette.primary.main,
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	link: {
		color: theme.palette.primary.main,
		textDecoration: "underline",
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.dark
		}
	},
	input: {
		padding: "0.6em"
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component='div'
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{children}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`
	};
}

const marriottData = {
	data: {
		firstName: "John",
		lastName: "Doe",
		hotelName: "Sheraton on the Park",
		email: "john@enteroffer.com",
		startDate: "2020-07-16",
		endDate: "2020-07-19",
		iterNo: 12314234,
		rooms: 1,
		adult: 1,
		child: 0,
		existingBooking: {
			title:
				"Grand Executive City Side, Club lounge access, Guest room, 1 King",
			price: 439
		},
		products: [
			{
				roomInfo: {
					"Special Benefits":
						"This room features Club lounge access, Open: Sun-Sat, Private access floor, Complimentary food: Continental breakfast & hors d'oeuvres, High-Speed Wi-Fi, Complimentary Cocktails, Complimentary pressing (2 garments per stay), Complimentary business services, Complimentary non-alcoholic beverages, Sheraton Club lounge located on Level 21",
					"Room Features":
						"70sqm/753sqft, Air-conditioned, This room is non-smoking, Walk-in closet, Windows, Soundproof, Hooks, USB Outlets, Living area is separated from bed by privacy wall, Living room is separated"
				},
				title:
					"Executive Suite, Club lounge access, 1 Bedroom Executive Suite, 1 King, Hyde Park view",
				image:
					"https://cache.marriott.com/marriottassets/marriott/SYDSI/sydsi-executive-4214-hor-wide.jpg?downsize=*:472px",
				id: 1981244097849,
				price: 607
			},
			{
				roomInfo: {
					"Special Benefits":
						"This room features Club lounge access, Open: Sun-Sat, Private access floor, Complimentary food: Continental breakfast & hors d'oeuvres, High-Speed Wi-Fi, Complimentary Cocktails, Complimentary pressing (2 garments per stay), Complimentary business services, Complimentary non-alcoholic beverages",
					"Room Features":
						"70sqm/753sqft, Air-conditioned, This room is non-smoking, Walk-in closet, Windows,Windows may be opened, Soundproof, Hooks, USB Outlets, Living room is separated, Living area is separated from bed by privacy wall"
				},
				title:
					"Terrace Suite, Club lounge access, 1 Bedroom Suite, 1 King, Hyde Park view, Partial harbor view",
				image:
					"https://cache.marriott.com/marriottassets/marriott/SYDSI/sydsi-king-terrace-suite-7654-hor-wide.jpg?downsize=*:472px",
				id: 1983944337849,
				price: 762
			}
		],
		currencySymbol: "$",
		currency: "AUD"
	},
	iat: 1573034433
};

const qtData = {
	data: {
		firstName: "John",
		lastName: "Doe",
		hotelName: "QT Sydney",
		email: "john@enteroffer.com",
		startDate: "2020-07-16",
		endDate: "2020-07-19",
		iterNo: 12345234,
		rooms: 1,
		adult: 1,
		child: 0,
		existingBookingTitle: "QT King",
		existingBooking: {
			title: "QT King",
			price: 253
		},
		products: [
			{
				roomInfo: {
					"Room Details":
						"Set your watch to cocktail hour and soak up the dignified delights. A luxurious king size bed surrounded by designer embellishments. A bathroom refined for you to take your time - double vanity, walk-in rainwater shower and bath complete with Kevin Murphy amenities. There’s a workspace with speedy internet to keep you switched on, plus all the entertainment extras. This is a room fit for a solo guest or up to as many as three."
				},
				title: "QT Deluxe King",
				image:
					"https://www.qthotels.com/sydney-cbd/wp-content/uploads/sites/91/2015/09/1028-QT-Sydney-20191127-2080x1120.jpg",
				id: 1983944097849,
				price: 314
			},
			{
				roomInfo: {
					"Room Details":
						"Slip into something superior. Higher floors prop up your luxurious king size bed in a room with space for more - two king singles or rollaway bed can be requested if you please. A designer bathroom with all the alluring additions - double vanity, walk-in rainwater shower and bath complete with Kevin Murphy amenities. There’s space for work with high-speed internet plus all the entertainment extras. A flatscreen HD TV with in-house movies for a traditionally cosy night-in."
				},
				title: "QT Executive King",
				image:
					"https://www.qthotels.com/sydney-cbd/wp-content/uploads/sites/91/2015/09/0932-QT-Sydney-20191127-2080x1120.jpg",
				id: 1983944093849,
				price: 326
			}
		],
		currencySymbol: "$",
		currency: "AUD"
	},
	iat: 1573035123
};

const hiltonData = {
	data: {
		firstName: "John",
		lastName: "Doe",
		hotelName: "Hilton Sydney",
		email: "john@enteroffer.com",
		startDate: "2020-07-16",
		endDate: "2020-07-19",
		iterNo: 12314234,
		rooms: 1,
		adult: 1,
		child: 0,
		existingBookingTitle: "HILTON EXECUTIVE ROOM",
		products: [
			{
				roomInfo: {
					"Special Benefits": "something",
					"Room Features": "somethingelse",
					"Room Ameneties": "somethingelseelse"
				},
				title: "KING HILTON GUEST ROOM",
				image:
					"https://www.hiltonsydney.com.au/wp-content/uploads/2019/02/hilton-king-guest-room-view-1024x760-840x760.jpg",
				id: 1983944045849,
				price: 300
			},
			{
				roomInfo: {
					"Special Benefits": "something",
					"Room Features": "somethingelse",
					"Room Ameneties": "somethingelseelse"
				},
				title: "HILTON DELUXE ROOM",
				image:
					"https://www.hiltonsydney.com.au/wp-content/uploads/2019/02/hilton-king-deluxe-room-view-1024x760-840x760.jpg",
				id: 1967944097849,
				price: 500
			}
		],
		currencySymbol: "$",
		currency: "AUD"
	},
	iat: 1573045133
};

function OfferPage(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [email, setEmail] = useState({
		email: "",
		emailEntered: false
	});
	const [anonymous, setAnonymous] = useState(true);
	const [submitting, setSubmitting] = useState(false);

	const [values, setValues] = useState({
		cartTotal: 0,
		data: {},
		storeName: "",
		loading: true,
		tabType: 0,
		tabName: "Choose Upgrade",
		errorType: 0,
		errorOpen: false,
		offerSuccessOpen: false,
		error: false,
		stripeError: "",
		deliveryLoading: false,
		tab1Colour: "#929292",
		tab2Colour: "#929292",
		userError: "",
		offerErrorOpen: false,
		offer: {},
		email: "",
		emailEntered: false,
		cartAbandoned: false,
		listTotal: 0,
		errorSubmitted: false,
		selectedOffer: {},
		selected: false
	});

	useEffect(() => {
		db.updateOfferUrl();
		db.isOnline();
		// console.log(window.location.search);
		let url =
			String(window.location.search) +
			"&s=productsRUs&url=oaklanding.myshopify.com&p=1764513382457&v=17402820231225";
		var parsed = QueryString.parse(url);

		if (parsed.p === undefined || parsed.s === undefined) {
			setValues({ ...values, error: true });
		} else {
			var res = qtData;
			if (parsed.h === "marriott") res = marriottData;
			else if (parsed.h === "hilton") res = hiltonData;
			setValues({
				...values,
				data: res.data,
				storeName: parsed.s,
				loading: false
			});
		}
		db.getMetaItem("email").then(snapshot => {
			if (
				snapshot !== null &&
				snapshot !== "" &&
				snapshot !== undefined
			) {
				setEmail({ ...email, email: snapshot, emailEntered: true });
			}
		});
		db.getMetaItem("anonymous").then(snapshot => {
			if (
				snapshot !== null &&
				snapshot !== "" &&
				snapshot !== undefined
			) {
				setAnonymous(snapshot);
			} else {
				setAnonymous(false);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	auth.onAuthStateChanged(async user => {
		if (
			window.location.hostname !== "localhost" &&
			window.location.hostname !== "127.0.0.1"
		) {
			user && analytics.setUserId(user.uid);
		}
	});

	function handleChangeIndex(index) {
		setValues({
			...values,
			tabType: index,
			tabName: index === 0 ? "Choose Upgrade" : "Offer"
		});
	}

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	const handleChangeTab = (event, type) => {
		if (type === 0) {
			setValues({
				...values,
				tabType: type,
				tabName: "Choose Upgrade"
			});
		} else if (type === 1 && !values.selected) {
			setValues({ ...values, errorType: 1, errorOpen: true });
		} else if (type === 1 && values.selected) {
			setValues({
				...values,
				tabType: 1,
				tabName: "Offer"
			});
		}
	};

	const callbackCloseError = param => {
		setValues({ ...values, errorOpen: false });
	};

	useEffect(() => {
		if (values.cardAdded) {
			submitOffer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.cardAdded]);

	const deleteAnonData = async flag => {
		if (flag) {
			await db.updateMetaItem("payment", null);
			await db.updateMetaItem("billingAddress", null);
			await db.updateMetaItem("shippingAddress", null);
		}
	};
	const submitOffer = async () => {
		var flag = true;
		var errorType = 0;
		var emptyFlag = true;
		let payment = "";
		for (let i in values.prices) {
			emptyFlag = false;
			if (values.cartAbandoned === true) {
				if (values.cartTotal >= values.listTotal) {
					setValues({ ...values, errorType: 3, errorOpen: true });
					errorType = 3;
					flag = false;
				}
				if (
					values.cartTotal === "" ||
					values.cartTotal === undefined ||
					values.cartTotal === null
				) {
					setValues({ ...values, errorType: 1, errorOpen: true });
					errorType = 1;
					flag = false;
				} else if (values.cartTotal < 1) {
					setValues({ ...values, errorType: 2, errorOpen: true });
					errorType = 2;
					flag = false;
				}
				break;
			} else {
				if (
					values.prices[i] === "" ||
					values.prices[i] === undefined ||
					values.prices[i] === null
				) {
					setValues({ ...values, errorType: 1, errorOpen: true });
					errorType = 1;
					flag = false;
				} else if (values.prices[i] < 1) {
					setValues({ ...values, errorType: 2, errorOpen: true });
					flag = false;
					errorType = 2;
				}

				for (let j in values.data.products) {
					if (values.data.products[j].offerable === true) {
						let temp = values.data.products[j];
						for (let k in temp.variants) {
							for (let n in values.variants) {
								if (
									temp.variants[k].id === values.variants[n]
								) {
									if (
										Number(i) === temp.id &&
										Number(values.prices[i]) >=
											Number(temp.variants[k].price)
									) {
										setValues({
											...values,
											errorType: 3,
											errorOpen: true
										});
										flag = false;
										errorType = 3;
									}
								}
							}
						}
					}
				}
			}
		}

		if (emptyFlag) {
			setValues({ ...values, errorType: 1, errorOpen: true });
			errorType = 1;
			flag = false;
		}
		if (flag) {
			await db.getMetaItem("firstName").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					flag = false;
					errorType = 4;
				}
			});

			await db.getMetaItem("lastName").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					flag = false;
					errorType = 4;
				}
			});

			await db.getMetaItem("email").then(snapshot => {
				if (_.isEmpty(snapshot) && values.email === "") {
					setValues({ ...values, errorType: 4, errorOpen: true });
					flag = false;
					errorType = 4;
				}
			});

			await db.getAddress("shippingAddress").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					flag = false;
					errorType = 4;
				}
			});

			await db.getAddress("billingAddress").then(snapshot => {
				if (_.isEmpty(snapshot) && flag) {
					setValues({ ...values, errorType: 5, errorOpen: true });
					flag = false;
					errorType = 5;
				}
			});

			if (_.isEmpty(values.selectedShipping) && flag) {
				setValues({ ...values, errorType: 6, errorOpen: true });
				flag = false;
				errorType = 6;
			}
		}

		if (values.tabType === 2 && flag) {
			payment = await db.getPayment();
			if (
				payment === null ||
				payment === undefined ||
				payment === "" ||
				payment.token === null ||
				payment.token === undefined ||
				payment.token === ""
			) {
				flag = false;
				errorType = 7;
			}
			if (
				flag &&
				(values.selectedShipping === "" ||
					values.selectedShipping === undefined ||
					values.selectedShipping === null ||
					values.selectedShipping.valid === false)
			) {
				flag = false;
				errorType = 6;
			}
		}
		if (flag) {
			setSubmitting(true);
			var meta = await db.getMeta();
			let payment = meta.payment;
			let shippingAddress = meta.shippingAddress;
			let billingAddress = meta.billingAddress;
			let names = {
				firstName: meta.firstName,
				lastName: meta.lastName
			};
			let email = meta.email;
			shippingAddress.email = email;
			shippingAddress.firstName = names.firstName;
			shippingAddress.lastName = names.lastName;
			billingAddress.email = email;
			billingAddress.firstName = names.firstName;
			billingAddress.lastName = names.lastName;

			var products = [];
			for (var i in values.data.products) {
				if (isNaN(Number(values.prices[values.data.products[i].id]))) {
					continue;
				}
				var vImg = "";
				var vType = "";
				var vPrice = 0;
				for (let j in values.data.products[i].variants) {
					if (
						values.data.products[i].variants[j].id ===
						Number(values.variants[values.data.products[i].id])
					) {
						vImg = values.data.products[i].variants[j].image;
						vType = values.data.products[i].variants[j].title;
						vPrice = values.data.products[i].variants[j].price;
					}
				}
				var prod = {
					offerable: values.data.products[i].offerable,
					title: values.data.products[i].title,
					variantID: Number(
						values.variants[values.data.products[i].id]
					),
					image: vImg,
					variantType: vType,
					price: vPrice,
					bidPrice: Number(values.prices[values.data.products[i].id]),
					productID: values.data.products[i].id,
					quantity:
						values.quantities[values.data.products[i].id] ===
							undefined ||
						values.quantities[values.data.products[i].id] === null
							? 1
							: values.quantities[values.data.products[i].id],
					inventoryId: Number(
						values.inventoryIds[values.data.products[i].id]
					)
				};
				products.push(prod);
			}

			var finalTotal =
				Number(values.cartTotal) +
				Number(values.selectedShipping.price) +
				Number(values.couponValue);
			var offer = {
				token: values.token,
				anonymous: meta.anonymous,
				shipping: values.selectedShipping.id,
				storeName: values.storeName,
				offer: products,
				total: finalTotal,
				paymentInfo: payment,
				shippingAddress: shippingAddress,
				billingAddress: billingAddress,
				cartAbandoned: values.cartAbandoned,
				cartTotal: values.cartTotal
			};
			if (values.couponToken !== "") {
				offer.couponToken = values.couponToken;
			}
			return await cf
				.submitOffer(offer)
				.then(res => {
					deleteAnonData(meta.anonymous);
					setTimeout(() => {
						setValues({
							...values,
							offerSuccessOpen: true,
							offer: offer
						});
						setSubmitting(false);
					}, 2000);
					if (
						window.location.hostname !== "localhost" &&
						window.location.hostname !== "127.0.0.1" &&
						values.storeName !== "productsRUs"
					) {
						FacebookPixel.trackCustom("OfferSubmitted", {
							storeName: values.storeName,
							cartTotal: values.cartTotal
						});
						analytics.logEvent("offer_submitted", {
							storeName: values.storeName,
							cartTotal: values.cartTotal
						});
						analytics.setUserProperties({ offer_submitted: true });
					}
				})
				.catch(e => {
					console.error(e);
					setValues({ ...values, offerErrorOpen: true });
					setSubmitting(false);
					throw e;
				});
		} else {
			setValues({ ...values, errorType: errorType, errorOpen: true });
			return null;
		}
	};

	// Ryan to push the error message to email.
	function handleSendError() {
		setValues({ ...values, errorSubmitted: true });
		cf.errorEmail(values.errorUser, values.errorUserEmail)
			.then(res => {
				//handle
			})
			.catch(res => {
				//handle
			});
	}

	const selectionCallback = obj => {
		setValues({
			...values,
			selectedOffer: obj,
			selected: true,
			tabType: 1,
			tabName: "Offer"
		});
	};

	const darkModeCallback = e => {
		props.darkModeCallback(e);
	};

	if (values.loading === false) {
		if (!values.offerSuccessOpen) {
			var retailerLogo = `https://s3-ap-southeast-2.amazonaws.com/oaklas/logos/${values.storeName}Logo.png`;
			let parsed = QueryString.parse(window.location.search);
			return (
				(window.location.hash = values.tabName),
				(
					<div style={{ fontFamily: props.font }}>
						<Header
							username={`${values.data.firstName} ${values.data.lastName}`}
							darkModeCallback={darkModeCallback}
						/>

						<CssBaseline />
						<ErrorSnackbar
							errorType={values.errorType}
							stripeError={values.stripeError}
							open={values.errorOpen}
							parentCallbackCloseError={callbackCloseError}
						/>
						<div
							className={classes.root}
							style={{ marginTop: "4.8em" }}>
							<Paper
								elevation={0}
								className={classes.paper}
								style={{
									position: "static",
									width: props.isMobile
										? "-webkit-fill-available"
										: 500,
									zIndex: "1",
									marginTop: props.isMobile ? "-12px" : "-2px"
								}}>
								<Tabs
									value={values.tabType}
									onChange={handleChangeTab}
									classes={{
										indicator: classes.indicator
									}}
									variant='fullWidth'
									style={{
										fontSize: "2.5em"
									}}>
									<Tab
										label={<span>Choose Upgrade</span>}
										disabled={submitting}
										style={{
											fontSize: "0.4em",
											backgroundColor: "#fff !important",
											color:
												values.tabType === 0
													? theme.palette.secondary
															.dark
													: values.tab1Colour,
											fontWeight: "700"
										}}
										{...a11yProps(0)}
									/>
									<Tab
										label={<span>Offer</span>}
										disabled={submitting}
										style={{
											backgroundColor: "#fff !important",
											fontSize: "0.4em",
											color:
												values.tabType === 1
													? theme.palette.secondary
															.dark
													: values.tab2Colour,
											fontWeight: "700"
										}}
										{...a11yProps(1)}
									/>
								</Tabs>
							</Paper>
							<Paper
								elevation={0}
								className={classes.paper}
								style={{
									padding: "1em 1em",
									textAlign: "center"
								}}>
								<ReactCSSTransitionGroup
									transitionName='loadingItem'
									transitionAppear={true}
									transitionAppearTimeout={500}
									transitionEnterTimeout={500}
									transitionLeaveTimeout={300}>
									<img
										alt={`${values.storeName}`}
										src={
											parsed.h === "marriott"
												? "https://seeklogo.com/images/S/sheraton-2019-logo-46F4CCD9AE-seeklogo.com.png"
												: parsed.h === "hilton"
												? "http://seekvectorlogo.com/wp-content/uploads/2018/01/hilton-hotels-resorts-vector-logo.png"
												: "https://upload.wikimedia.org/wikipedia/en/1/17/QT_Hotels_%26_Resorts_Logo.png"
										}
										style={{
											maxWidth: "60%",
											maxHeight: " 60px",
											display: "inline-block",
											objectFit: "cover",
											backgroundColor: "#fff"
										}}
									/>
								</ReactCSSTransitionGroup>
							</Paper>
							<SwipeableViews
								disabled={!email.emailEntered}
								axis={
									theme.direction === "rtl"
										? "x-reverse"
										: "x"
								}
								index={values.tabType}
								onChangeIndex={handleChangeIndex}>
								<TabPanel
									value={values.tabType}
									index={0}
									dir={theme.direction}>
									<Paper
										elevation={0}
										className={classes.paper}
										style={{
											padding: "1em",
											marginBottom:
												values.data.products.length ===
												1
													? "1em"
													: "12em"
										}}>
										{values.deliveryLoading === false ? (
											<Products
												data={values.data}
												selectionCallback={
													selectionCallback
												}
												cartAbandoned={
													values.cartAbandoned
												}
											/>
										) : null}
									</Paper>
								</TabPanel>
								<TabPanel
									value={values.tabType}
									index={1}
									dir={theme.direction}>
									<Paper
										elevation={0}
										className={classes.paper}
										style={{
											padding: "1em",
											marginBottom:
												values.data.products.length ===
												1
													? "1em"
													: "12em"
										}}>
										{!values.selected ? null : (
											<OfferSelected
												data={values.data}
												product={values.selectedOffer}
											/>
										)}
									</Paper>
								</TabPanel>
							</SwipeableViews>
						</div>
					</div>
				)
			);
		} else {
			return (
				<Submit
					font={props.font}
					offer={values.offer}
					storeName={values.storeName}
					cartTotal={values.cartTotal}
					shipping={values.selectedShipping}
					response={values.data.response}
				/>
			);
		}
	} else {
		return (
			<div>
				<Header
					username={``}
					preload={true}
					size={true}
					darkModeCallback={darkModeCallback}
				/>
				<div style={{ display: "inline-block" }}>
					<Lottie
						height={200}
						width={200}
						options={{
							loop: true,
							autoplay: true,
							animationData: loader.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
						style={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%) translateX(-50%)",
							left: "50%"
						}}
					/>
				</div>
			</div>
		);
	}
}

const mapSizesToProps = ({ width, height }) => ({
	isMobile: width < 600,
	isSmallMobile: height < 580
});

export default withSizes(mapSizesToProps)(OfferPage);
